:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vanilla:hover {
  filter: drop-shadow(0 0 2em #f7df1eaa);
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.grid-container {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto 1fr auto auto;
  grid-template-areas:
    "header header header"
    "nav nav nav"
    "main main main"
    "img img img"
    "footer footer footer";
}

.header {
  grid-area: header;
  font-size: 1.5rem;
  background-color: #069;
  color: white;
  text-align: center;
  padding: 1rem;
}

.nav {
  grid-area: nav;
  background-color: #333;
  color: white;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
}

.link-list {
  display: flex;
  margin: 0;
  list-style: none;
}

.link {
  padding: .5rem;
}

.link:hover {
  background-color: #777;
}

.link a {
  color: inherit;
  text-decoration: none;
}

.main {
  grid-area: main;
  margin: 1rem;
}

.hero {
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
}

.articles {
  display: flex;
  font-size: 1rem;
  padding: 1rem;
  margin: 1rem;
}

.articles h3, p {
  margin: 1rem;
}

.footer {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  background-color: #069;
  color: white;
  text-align: center;
  padding: .5rem;
}

.img {
  grid-area: img;
  padding: 1rem;
  max-width: 30%;
}